<template>
  <div class="about_application_container">
    <!-- 顶部导航占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 在线报名标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 在线报名内容 -->
    <ApplicationContent></ApplicationContent>
  </div>
</template>

<script>
import ApplicationContent from './components/application-content.vue'
export default {
  name: 'Application',
  components: {
    ApplicationContent
  },
  data() {
    return {
      title: '课程在线报名申请',
      complement: 'CASE PRESENTATION'
    }
  }
}
</script>

<style lang="less" scoped></style>
