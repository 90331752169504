<template>
  <div class="application_content_container w">
    <div class="logo">
      <img src="@/assets/images/about/qb_logo3.png" alt="" />
    </div>
    <div class="split_line"></div>
    <div class="application_table">
      <div class="title">课程报名申请表</div>
      <div class="remake">
        请确保以下信息真实有效，一经审核通过即生效，*号项为必填项。
      </div>
      <div class="form">
        <el-form
          :model="formData"
          :rules="rules"
          label-width="356px"
          ref="form"
        >
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="您要参加的场次：" prop="session">
            <el-radio-group v-model="formData.session">
              <el-radio
                label="2021年10月12日——14日·上海站·《裂变式盈利》"
              ></el-radio>
              <el-radio
                label="2021年10月12日——14日·成都站·《裂变式盈利》"
              ></el-radio>
              <el-radio
                label="2021年10月12日——14日·云南站·《裂变式盈利》"
              ></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="公司名称：" prop="companyName">
            <el-input v-model="formData.companyName"></el-input>
          </el-form-item>
          <el-form-item label="职位：" prop="position">
            <el-input v-model="formData.position"></el-input>
          </el-form-item>
          <el-form-item label="手机：" prop="phone">
            <el-input v-model="formData.phone"></el-input>
          </el-form-item>
          <el-form-item label="推荐人：" prop="referrer">
            <el-input v-model="formData.referrer"></el-input>
          </el-form-item>
        </el-form>
        <div class="remind">
          请确保以上信息真实有效。感谢您关注上海泉邦网络科技有限公司！<br />
          联系电话：4009997507 我们将尽快答复您的申请！
        </div>
        <el-button type="primary" class="btn" @click="handleSubmit">
          提交
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationContent',
  data() {
    return {
      formData: {
        name: '',
        session: '',
        companyName: '',
        position: '',
        phone: '',
        referrer: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '手机号格式错误' }
        ],
        session: [{ required: true, message: '请选择场次', trigger: 'change' }],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5]{2,12}$/, message: '手机号格式错误' }
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5]{2,12}$/, message: '手机号格式错误' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误' }
        ]
      }
    }
  },
  methods: {
    async handleSubmit() {
      await this.$refs.form.validate(async vaild => {
        if (vaild) await this.$refs.form.resetFields()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.application_content_container {
  display: flex;
  height: 1991px;
  .logo {
    width: 288px;
    height: 275px;
    margin-left: 216px;
    margin-right: 77px;
    margin-top: 508px;
  }
  .split_line {
    width: 1px;
    height: 1409px;
    border: 1px solid #979797;
    margin-top: 147px;
  }
}

.application_table {
  display: flex;
  flex-direction: column;
  width: 1335px;
  .title {
    font-size: 64px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #024580;
    line-height: 89px;
    margin-top: 85px;
    margin-left: 447px;
  }
  .remake {
    margin-top: 103px;
    margin-left: 132px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 44px;
    margin-bottom: 107px;
  }
  ::v-deep .el-form {
    .el-form-item {
      margin-bottom: 55px;
    }
    .el-form-item__label {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
      margin-top: 17px;
    }
    .el-radio-group {
      margin-top: 18px;
      margin-left: 40px;
    }
    .el-radio {
      margin-bottom: 55px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .el-input__inner {
      width: 624px;
      height: 80px;
      border: 1px solid #979797;
      margin-left: 43px;
      font-size: 37px;
      color: #000;
    }

    .el-radio__label {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 44px;
    }
    .el-radio__inner {
      width: 27px;
      height: 27px;
      border: 1px solid #979797;
      margin-right: 28px;
      &::after {
        width: 21px !important;
        height: 21px !important;
        background-color: #024580;
      }
    }
    .el-radio__input {
      margin-bottom: 12px;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background-color: #fff;
    }
    .el-form-item__error {
      display: none;
    }
  }
  .remind {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #024580;
    line-height: 44px;
    margin-left: 132px;
  }
  .btn {
    margin-top: 91px;
    margin-left: 479px;
    width: 255px;
    height: 79px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 39px;
    background: linear-gradient(127deg, #98baff 0%, #3d7eff 100%);
    border: 0;
  }
}
</style>
